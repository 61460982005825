<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('orderstatus', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-card-text>
        <v-row class="mx-0 px-0">
          <v-col cols="12" :class="{ 'pr-3': $vuetify.breakpoint.smAndUp }">
            <ValidationProvider
              vid="extra-name"
              :name="$tc('name', 1)"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                autofocus
                outlined
                :disabled="!status.dynamic || !status.editable"
                v-model="status.name"
                :label="$t('name')"
                :error-messages="errors[0]"
                @keydown.enter="submit"
                key="extra-name-input"
              />
            </ValidationProvider>
          </v-col>

          <v-col sm="12" cols="12">
            <v-alert
              class="order-status-alert"
              border="top"
              colored-border
              type="info"
              dense
              elevation="2"
              v-model="show"
              dismissible
            >
              <span>{{ $t('editable_active') }}</span>
            </v-alert>
          </v-col>

          <v-col cols="12" sm="6" md="5">
            <v-row>
              <v-col sm="6" cols="12" v-show="status.dynamic">
                <v-switch
                  v-model="status.editable"
                  :disabled="!adminRole"
                  :label="$t('editable')"
                  name="editable"
                  color="secondary"
                  key="editable-input"
                />
              </v-col>
              <v-col sm="1" cols="12" v-show="status.dynamic">
                <v-icon
                  color="info"
                  small
                  @click="show = !show"
                  v-text="'fa-info-circle'"
                />
              </v-col>
              <v-col sm="12" cols="12">
                <v-switch
                  v-model="status.dark"
                  :disabled="!canEdit"
                  :label="$t('dark')"
                  name="dark"
                  color="secondary"
                  key="dark-input"
                />
              </v-col>
              <v-col sm="12" cols="12">
                <v-switch
                  v-model="status.dynamic"
                  disabled
                  :label="$t('dynamic')"
                  name="dynamic"
                  color="secondary"
                  key="dynamic-input"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="6" md="7">
            <v-row justify-center align-center>
              <v-col cols="12">
                <v-color-picker v-model="status.color" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="status.root > 0">
          <v-col class="primary--text subtitle-2 font-weight-black">
            <span>{{ $t('notifications') }}</span>
          </v-col>
        </v-row>

        <v-row v-if="status.root > 0">
          <v-col
            v-for="(label, index) in notificationLabels"
            :key="index"
            cols="4"
          >
            <v-switch
              v-model="status[label.key]"
              :label="$tc(label.label, 1)"
              :disabled="!canEdit"
              name="notification"
              color="primary"
              :key="`${label.key}-input`"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </i-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      edit: false,
      show: false,
      status: {
        pk: '',
        color: '#ffffff',
        root: null,
        position: 1,
        name: '',
        dark: false,
        editable: false,
        dynamic: true,
        email: false,
        push: false,
        whatsapp: false
      },
      notificationLabels: [
        { key: 'push', label: 'push' },
        { key: 'email', label: 'email' },
        { key: 'whatsapp', label: 'whatsapp' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'session/isAdmin',
      me: 'session/me'
    }),
    adminRole() {
      return this.me.groups.some((item) => item.name === 'Administrator')
    },
    canEdit() {
      return this.status.editable || this.adminRole
    }
  },
  methods: {
    /**
     * submit
     * Envía los datos del estado actual a la API para crear o editar un
     * estado.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          const status = this.edit
            ? await this.$api.sale.status.edit({
                pk: this.status.pk,
                form: this.status
              })
            : await this.$api.sale.status.create({ form: this.status })
          this.$emit('done', status.data)
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('status', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit.pk !== ''
          this.status = { ...this.toEdit }
        }
      }
    },
    'status.color': {
      handler(val) {
        if (typeof val === 'object') {
          this.status.color = val.hex
        }
      }
    }
  }
}
</script>
